import React from 'react';
import {About} from "./components/about/about";
import {Projects} from "./components/projects/projects";

export const App = () => {
    return (
        <>
            <div className="bg-white dark:bg-gray-800">
                <About />
            </div>
            <div className="bg-white dark:bg-gray-800">
                <Projects />
            </div>
        </>
    );
}
