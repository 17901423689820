import {Skills} from "../skills/skills";
import {FaGithub, FaTwitter, FaLinkedin} from "react-icons/fa";

export const About = () => {
    let skills = [
        {
            name: "C#",
            img: "csharp.svg"
        },
        {
            name: ".NET Core",
            img: "netcore.svg"
        },
        {
            name: "Kotlin",
            img: "kotlin.svg"
        },
        {
            name: "Android",
            img: "android.svg"
        },
        {
            name: "Powershell",
            img: "powershell.svg"
        },
        {
            name: "IntelliJ",
            img: "intellij.svg"
        },
        {
            name: "JetBrains Rider",
            img: "rider.png"
        },
        {
            name: "Android Studio",
            img: "android_studio.svg"
        },
        {
            name: "Visual Studio",
            img: "visual_studio.svg"
        },
        {
            name: "Visual Studio Code",
            img: "visual_studio_code.svg"
        },
        {
            name: "Git and GitFlow",
            img: "git.svg"
        },
        {
            name: "GitHub",
            img: "github.png"
        },
        {
            name: "GitLab (CI/CD)",
            img: "gitlab.svg"
        },
        {
            name: "MariaDB",
            img: "mariadb.svg"
        },
        {
            name: "Postgresql",
            img: "postgres.svg"
        },
        {
            name: "Redis",
            img: "redis.svg"
        },
        {
            name: "Docker and Docker Swarm",
            img: "docker.svg"
        },
    ]

    let learning = [
        {
            name: "Rust",
            img: "rust.svg"
        },
        {
            name: "TypeScript",
            img: "typescript.svg"
        },
        {
            name: "React",
            img: "react.svg"
        },
        {
            name: "Angular",
            img: "angular.svg"
        },
        {
            name: "Tailwind CSS",
            img: "tailwindcss.svg"
        },
        {
            name: "Kubernetes",
            img: "k8s.svg"
        },
    ]

    return(
        <section
            id="about"
            className="flex flex-col max-w-md px-6 py-12 mx-auto sm:px-10 sp:px-10 md:px-8 sm:max-w-2xl md:max-w-3xl lg:max-w-5xl sm:py-5 md:py-6 lg:py-8"
        >
            <div className="w-48 h-48 mx-auto mt-12 mb-10 overflow-hidden rounded-full md:w-56 md:h-56 md:mb-12">
                <img src="/assets/img/ibai.jpg" alt="me"/>
            </div>
            <div className="text-gray-800 dark:text-white">
                <div className="mx-auto mb-24">
                    <p className="font-bold leading-snug text-left text-1xl md:text-center sm:text-2xl md:text-3xl lg:text-4xl font-header">
                        Hello there 👋, I'm Ibai.
                    </p>
                    <p className="leading-snug text-left text-1xl md:text-center sm:text-1xl md:text-2xl lg:text-3xl">
                        I like software development, space and science.
                    </p>
                    <div className="flex align-center justify-center mt-4">
                        <a className="text-xl m-1 p-1 sm:m-2 sm:p-2 text-gray-400 hover:bg-gray-400 rounded-full hover:text-white transition-colors duration-300" href="https://github.com/lllibailll">
                            <FaGithub/>
                            <span className="sr-only">Github</span>
                        </a>
                        <a className="text-xl m-1 p-1 sm:m-2 sm:p-2 text-blue-500 hover:bg-blue-500 rounded-full hover:text-white transition-colors duration-300" href="https://twitter.com/lllibailll">
                            <FaTwitter/>
                            <span className="sr-only">Twitter</span>
                        </a>
                    </div>
                </div>
                <div className="mb-24">
                    <h3 className="mb-8 text-xl font-bold text-center uppercase md:text-2xl font-header text-primary dark:text-secondary">
                        Skills
                    </h3>
                    <Skills skills={skills}/>
                </div>
                <div className="mb-24">
                    <h3 className="mb-8 text-xl font-bold text-center uppercase md:text-2xl font-header text-primary dark:text-secondary">
                        Learning
                    </h3>
                    <Skills skills={learning}/>
                </div>
            </div>
        </section>
    )
}
