import ReactTooltip from "react-tooltip";
import {Project} from "../project/project";

let projects = [
    {
        name: "BackPlay",
        img: "backplay.png",
        shortDesc: "Minecraft network",
        desc: "BackPlay is a competitive Minecraft PvP network focused on Survival Games.",
        web: "https://backplay.net",
        twitter: "BackPlayNetwork"
    }
]

export const Projects = () => {
    return (
        <section
            id="projects"
            className="flex flex-col max-w-md px-6 py-12 mx-auto sm:px-10 sp:px-10 md:px-8 sm:max-w-2xl md:max-w-3xl lg:max-w-5xl sm:py-5 md:py-6 lg:py-8"
        >
            <div className="text-gray-800 dark:text-white">
                <div className="mb-24">
                    <h3 className="mb-8 text-xl font-bold text-center uppercase md:text-2xl font-header text-primary dark:text-secondary">
                        Projects
                    </h3>
                    <div className="flex flex-wrap justify-center mx-auto lg:max-w-3xl">
                        {projects.map(x => (
                            <div className="flex p-4 md:p-8">
                                <Project project={x}/>
                            </div>
                        ))}
                        <ReactTooltip place="bottom" effect="solid"/>
                    </div>
                </div>
            </div>
        </section>
    )
}
