import ReactTooltip from "react-tooltip";
import {ISkill} from "../../ISkill";

export const Skills = ({skills}: {skills: ISkill[]}) => {
    return (
        <div className="flex flex-wrap justify-center mx-auto lg:max-w-3xl">
            {skills.map(x => (
                <div className="flex p-4 md:p-8">
                <span
                    data-tip={x.name}
                    data-offset="{'top': -10}"
                    data-class="tooly">
                    <img
                        src={`/assets/img/${x.img}`}
                        className="w-16 transition duration-500 ease-in-out transform md:w-20 hover:scale-110"
                        alt={x.img}
                    />
                </span>
                </div>
            ))}
            <ReactTooltip place="bottom" effect="solid"/>
        </div>
    )
}
