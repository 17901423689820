import {IProject} from "../../IProject";
import {BiWorld} from "react-icons/all";
import {FaGithub, FaTwitter} from "react-icons/fa";

export const Project = ({project}: {project: IProject}) => {
    return (
        <div className="relative">
            <div className="text-center mb-4 absolute -top-16 right-1/2 transform translate-x-1/2">
                <a href="#" className="block relative">
                    <img alt="profil" src={`/assets/img/projects/${project.img}`} className="mx-auto object-cover rounded-lg h-40 w-40"/>
                </a>
            </div>
            <div className="bg-white dark:bg-gray-700 rounded-lg shadow px-8 py-4 pt-24">
                <div className="text-center">
                    <p className="text-2xl text-gray-700 dark:text-white">
                        {project.name}
                    </p>
                    <p className="text-xl text-gray-500 dark:text-gray-200 font-light">
                        {project.shortDesc}
                    </p>
                    <p className="text-md text-gray-500 w-60 dark:text-gray-400 mx-auto py-4 font-light">
                        {project.desc}
                    </p>
                </div>

                <div className="flex align-center justify-center mt-4">
                    {project.web ?
                        <a className="text-xl m-1 p-1 sm:m-2 sm:p-2 text-gray-400 hover:bg-gray-400 rounded-full hover:text-white transition-colors duration-300" href={project.web} target="_blank">
                            <BiWorld/>
                            <span className="sr-only">Web</span>
                        </a> : undefined
                    }

                    {project.twitter ?
                        <a className="text-xl m-1 p-1 sm:m-2 sm:p-2 text-blue-500 hover:bg-blue-500 rounded-full hover:text-white transition-colors duration-300" href={`https://twitter.com/${project.twitter}`} target="_blank">
                            <FaTwitter/>
                            <span className="sr-only">Twitter</span>
                        </a> : undefined
                    }

                    {project.github ?
                        <a className="text-xl m-1 p-1 sm:m-2 sm:p-2 text-gray-400 hover:bg-gray-400 rounded-full hover:text-white transition-colors duration-300" href={`https://github.com/${project.github}`} target="_blank">
                            <FaGithub/>
                            <span className="sr-only">GitHub</span>
                        </a> : undefined
                    }
                </div>
            </div>
        </div>
    )
}
